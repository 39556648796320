import Flickity from "flickity-fade";

const Slideshow = {
  init: (index = null) => {
    Slideshow.slideshow = document.querySelector(".js-slideshow");
    Slideshow.slides = document.querySelectorAll(".js-slide");
    Slideshow.prev = document.querySelector(".js-prev");
    Slideshow.next = document.querySelector(".js-next");
    Slideshow.number = document.querySelector(".js-currentNumber");
    Slideshow.captions = document.querySelectorAll(".js-slideCaption");
    Slideshow.indexCaptions = document.querySelectorAll(".js-indexCaption");

    Slideshow.slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "center",
      setGallerySize: false,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: false,
      selectedAttraction: 0,
      friction: 0,
      lazyLoad: false,
      fade: true,
    };

    if (Slideshow.slideshow) {
      const carousel = Slideshow.flickity(Slideshow.slideshow, Slideshow.slideOptions);
    }

    if (index != null) {
      Slideshow.slider.selectCell(index, true, true);
      var i = document.querySelectorAll(".js-slide")[index].dataset.order - 1;
      Slideshow.indexCaptions[i].classList.add("active");
    }
  },

  flickity: (element, options) => {
    Slideshow.slider = new Flickity(element, options);

    if (Slideshow.prev) {
      Slideshow.prev.addEventListener("click", function () {
        Slideshow.slider.previous("true");
      });
    }

    if (Slideshow.next) {
      Slideshow.next.addEventListener("click", function () {
        Slideshow.slider.next("true");
      });
    }

    Slideshow.slider.on("change", function (index) {
      if (Slideshow.captions.length > 0) {
        Slideshow.captions.forEach((caption) => {
          caption.classList.remove("active");
        });
        Slideshow.captions[index].classList.add("active");
      }

      if (Slideshow.indexCaptions.length > 0) {
        var i = document.querySelectorAll(".js-slide")[index].dataset.order - 1;
        Slideshow.indexCaptions.forEach((caption) => {
          caption.classList.remove("active");
        });
        Slideshow.indexCaptions[i].classList.add("active");
      }
    });

    return Slideshow.slider;
  },

  destroy: () => {
    Slideshow.slider.destroy();
    Slideshow.indexCaptions.forEach((caption) => {
      caption.classList.remove("active");
    });
  },
};

export default Slideshow;
