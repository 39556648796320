const Scroll = {
  init: () => {
    Scroll.sidebar = document.querySelector(".js-sidebar");
    Scroll.content = document.querySelector(".content");

    document.querySelector("#container").addEventListener(
      "wheel",
      function (e) {
        console.log(this.children);
        if (!(this.children[2] && this.children[2].classList.contains("js-noScroll"))) {
          var oldVal = Scroll.sidebar.scrollTop;
          var scrollHeight = Scroll.sidebar.scrollHeight - window.innerHeight + 50;
          var variation = parseInt(e.deltaY);
          var newVal = oldVal + variation;
          newVal = newVal < 0 ? 0 : newVal;
          newVal = newVal > scrollHeight ? scrollHeight : newVal;

          Scroll.sidebar.scroll(0, newVal);
          oldVal = newVal;
          return false;
        }
      },
      true
    );

    if (window.matchMedia("(max-width: 575px)").matches) {
      var scrollPos = 0;
      Scroll.sidebar.addEventListener("scroll", function () {
        if (Scroll.sidebar.scrollTop > scrollPos && Scroll.sidebar.scrollTop > 10) {
          Scroll.content.classList.add("scroll--down");
        } else {
          Scroll.content.classList.remove("scroll--down");
        }
        scrollPos = Scroll.sidebar.scrollTop;
      });

      var noScroll = document.querySelector(".js-noScroll");
      if (noScroll) {
        var scrollPosAbout = 0;
        noScroll.addEventListener("scroll", function () {
          if (noScroll.scrollTop > scrollPos && noScroll.scrollTop > 10) {
            Scroll.content.classList.add("scroll--down");
          } else {
            Scroll.content.classList.remove("scroll--down");
          }
          scrollPos = noScroll.scrollTop;
        });
      }
    }
  },
};

export default Scroll;
