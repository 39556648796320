import "./_lib/modernizr";
import "../scss/application.scss";

import "nodelist-foreach-polyfill";
import "mdn-polyfills/Array.prototype.forEach";
import "mdn-polyfills/Array.prototype.find";
import "mdn-polyfills/Array.prototype.includes";
import "mdn-polyfills/String.prototype.includes";
import lazySizes from "lazysizes";

import Swup from "swup";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupJsPlugin from "@swup/js-plugin";

import playIcon from "../icons/player-play.svg";

import Transition from "./_modules/transition";

document.addEventListener("DOMContentLoaded", function () {
  if (!window.matchMedia("(min-width: 576px)").matches && document.querySelector(".js-iframe")) {
    window.location.href = "/home";
  }

  const options = [
    {
      from: "(.*)",
      to: "(.*)",
      in: function (next) {
        Transition.in(next);
      },
      out: function (next) {
        Transition.out(next);
      },
    },
  ];

  const swup = new Swup({
    plugins: [new SwupPreloadPlugin(), new SwupJsPlugin(options)],
    requestHeaders: {
      "X-Requested-With": "swup",
      Accept: "*",
    },
    containers: ["#container"],
    linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  });

  Transition.init();
  Transition.window();

  swup.on("contentReplaced", function () {
    Transition.init();
  });

  document.querySelector(".js-sidebar").addEventListener("scroll", function () {
    if (!window.matchMedia("(max-width: 575px)").matches) {
      var items = document.querySelector(".js-sidebar").children;
      var before = new Array();
      [...items].forEach((item, index) => {
        if (item.offsetTop - document.querySelector(".js-sidebar").scrollTop < window.innerHeight / 2) before.push(index);
      });
      var last = items[before.length - 1];
      if (last.children[0].tagName.toLowerCase() == "a") {
        if (window.location.href != last.children[0].href && !document.querySelector(".container").children[1].classList.contains("about")) {
          swup.loadPage({
            url: last.children[0].href,
            method: "GET",
            customTransition: "default",
          });
        }
      }
    }
  });
});
