import Website from "./website";
import Slideshow from "./slideshow";
import Video from "./video";
import Scroll from "./scroll";
import Filters from "./filters";
import Indexpage from "./indexpage";

import TweenLite from "gsap";

const Transition = {
  init: () => {
    Website.init();
    Slideshow.init();
    Video.init();
    Scroll.init();
    Indexpage.init();
  },

  window: () => {
    var href = window.location.href;
    var items = document.querySelector(".js-sidebar").children;
    var articles = new Array();
    for (const item of items) {
      if (href == item.children[0].href) {
        articles.push(item);
      }
    }
    if (articles.length > 0) {
      setTimeout(function () {
        document.querySelector(".js-sidebar").scroll(0, articles[0].offsetTop - 80);
        articles[0].classList.add("active");
      }, 100);
    }
  },

  in: function (next) {
    var div = document.querySelector("#container").children[1];
    div.style.opacity = 0;
    TweenLite.to(div, 0.1, {
      opacity: 1,
      onComplete: next,
    });
  },

  out: (next) => {
    var div = document.querySelector("#container").children[1];
    div.style.opacity = 1;
    TweenLite.to(div, 0.1, {
      opacity: 0,
      onComplete: next,
    });
  },
};

export default Transition;
