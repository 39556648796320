import Plyr from "plyr";

const Video = {
  init: () => {
    Video.containers = document.querySelectorAll(".js-videoHTML");
    Video.containers.forEach(video => {
      const player = new Plyr(video, {
        controls: ["play-large"],
        iconUrl: "/assets/svg/sprite.svg",
        iconPrefix: "player"
        // autoplay: true,
      });
    });
  }
};

export default Video;
