const Filters = {
  init: () => {
    Filters.array = new Array();
    Filters.item = document.querySelectorAll(".js-filtersItem");
    Filters.gridItems = document.querySelectorAll(".js-indexItem");
    Filters.indexTitle = document.querySelector(".js-indexTitle");
    Filters.container = document.querySelector(".js-index");
    Filters.item.forEach((item) => {
      var filter = item.dataset.filter;
      Filters.array.push(filter);
      item.addEventListener("click", function () {
        if (Filters.array.length == Filters.item.length) Filters.emptyArray();
        if (item.classList.contains("active")) {
          Filters.removeFromArray(item);
        } else {
          Filters.addToArray(item);
        }
        Filters.filterGrid();
      });
    });

    if (Filters.indexTitle) {
      Filters.indexTitle.addEventListener("click", function () {
        if (!Filters.container.classList.contains("index--slideshow")) {
          Filters.emptyArray();
          Filters.fullArray();
          Filters.filterGrid();
        }
      });
    }
  },

  emptyArray: () => {
    Filters.array.length = 0;
    Filters.item.forEach((item) => {
      item.classList.remove("active");
    });
  },

  fullArray: () => {
    Filters.item.forEach((item) => {
      var filter = item.dataset.filter;
      Filters.array.push(filter);
      item.classList.add("active");
    });
  },

  removeFromArray: (item) => {
    var filter = item.dataset.filter;
    var index = Filters.array.indexOf(filter);
    Filters.array.splice(index, 1);
    item.classList.remove("active");
    if (Filters.array.length == 0) Filters.fullArray();
  },

  addToArray: (item) => {
    var filter = item.dataset.filter;
    Filters.array.push(filter);
    item.classList.add("active");
  },

  filterGrid: () => {
    Filters.gridItems.forEach((item) => {
      var active = false;
      for (const filter of Filters.array) {
        if (item.classList.contains(filter)) active = true;
      }
      if (active) {
        item.classList.remove("hidden");
        item.classList.add("js-slide");
      } else {
        item.classList.add("hidden");
        item.classList.remove("js-slide");
      }
    });
  },
};

export default Filters;
