import Slideshow from "./slideshow";

const Indexpage = {
  init: () => {
    Indexpage.items = document.querySelectorAll(".js-indexItem");
    Indexpage.container = document.querySelector(".js-index");
    Indexpage.filtersItem = document.querySelectorAll(".js-filtersItem");
    Indexpage.title = document.querySelector(".js-indexTitle");
    Indexpage.items.forEach((item) => {
      item.addEventListener("click", function () {
        if (Indexpage.container.classList.contains("index--slideshow")) {
          Indexpage.destroySlideshow();
        } else {
          Indexpage.createSlideshow(item);
        }
      });
    });
    if (Indexpage.title) {
      Indexpage.title.addEventListener("click", function () {
        if (Indexpage.container.classList.contains("index--slideshow")) {
          Indexpage.destroySlideshow();
        }
      });
    }
  },

  createSlideshow: (item) => {
    Indexpage.container.classList.add("index--slideshow");
    Indexpage.container.children[0].classList.add("js-slideshow");
    Indexpage.filtersItem.forEach((item) => {
      item.classList.add("inactive");
    });
    var navA = document.createElement("div");
    var navB = document.createElement("div");
    navA.classList = "index__nav index__nav--prev js-prev";
    navB.classList = "index__nav index__nav--next js-next";
    Indexpage.container.appendChild(navA);
    Indexpage.container.appendChild(navB);
    Indexpage.items.forEach((item) => {
      var image = item.children[0];
      image.src = image.dataset.slide;
    });
    var index = Array.prototype.indexOf.call(document.querySelectorAll(".js-indexItem:not(.hidden)"), item);
    Slideshow.init(index);
  },

  destroySlideshow: () => {
    Indexpage.container.classList.remove("index--slideshow");
    Indexpage.container.children[0].classList.remove("js-slideshow");
    Indexpage.filtersItem.forEach((item) => {
      item.classList.remove("inactive");
    });
    document.querySelector(".js-prev").outerHTML = "";
    document.querySelector(".js-next").outerHTML = "";
    Slideshow.destroy();
    Indexpage.items.forEach((item) => {
      var image = item.children[0];
      image.src = image.dataset.index;
    });
    var container = Indexpage.container.children[0];
    [].map
      .call(container.children, Object)
      .sort(function (a, b) {
        return +a.dataset.order - +b.dataset.order;
      })
      .forEach(function (elem) {
        container.appendChild(elem);
      });
  },
};

export default Indexpage;
